<template lang="pug">
    .row
        .col-md-offset-2.col-md-8
            form.form-horizontal(name="formDepartment" v-if="!loading" @submit.prevent="editDepartment()")
                .form-group
                    label.col-sm-4.control-label.star-required(for="name") {{'departments.form.NAME'|translate}}
                    .col-sm-8
                        input#name.form-control(v-model="department.name")
                        OptiValidate(f="name" :data="$v.department.name")
                .form-group
                    label.col-sm-4.control-label.star-required(for="short") {{'departments.form.SHORTNAME'|translate}}
                    .col-sm-8
                        input#short.form-control(v-model="department.short")
                        OptiValidate(f="short" :data="$v.department.short")
                .form-group
                    label.col-sm-4.control-label(for="type") {{'departments.form.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="DepartmentType" :value="department.type" @update-enum="v => department.type = v")
                .form-group
                    label.col-sm-4.control-label(for="status") {{'departments.form.STATUS'|translate}}
                    .col-sm-8
                        OptiSelectEnum#status(enum="DepartmentStatus" :value="department.status" @update-enum="s => department.status = s")
                .form-group(v-show="department.status === 3")
                    label.col-sm-4.control-label.star-required(for="closing_date") {{'departments.form.CLOSINGDATE'|translate}}
                    .col-sm-8
                        OptiDate#closing_date(:value="department.closing_date" @update-date="d => department.closing_date = d")
                        OptiValidate(f="closing_date" :data="$v.department.closing_date")
                .form-group(v-show="department.status === 3")
                    label.col-sm-4.control-label(for="last_book_date") {{'departments.form.LASTBOOKDATE'|translate}}
                    .col-sm-8
                        OptiDate#last_book_date(:value="department.last_book_date" @update-date="l => department.last_book_date = l")

                .form-group(style="padding-top:30px")
                    .col-sm-8.col-sm-offset-4
                        i.fa.fa-home.mr3
                        span {{'departments.form.address.DEPARTMENT'|translate}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="city") {{'departments.form.CITY'|translate}}
                    .col-sm-8
                        OptiSelectCity(:city="department.city" @selected="selected")
                        OptiValidate(f="city" :data="$v.department.city")
                .form-group
                    label.control-label.col-sm-4.star-required(for="postal") {{'departments.form.POSTAL'|translate}}
                    .col-sm-8
                        input#postal.form-control(v-model="department.postal")
                        OptiValidate(f="postal" :data="$v.department.postal")
                .form-group
                    label.col-sm-4.control-label.star-required(for="street") {{'departments.form.STREET'|translate}}
                    .col-sm-8
                        input#street.form-control(v-model="department.street")
                        OptiValidate(f="street" :data="$v.department.street")

                .form-group(style="padding-top:30px")
                    .col-sm-8.col-sm-offset-4
                        i.fa.fa-home.mr3
                        span {{'departments.form.address.FIRM'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="firm_name") {{'departments.form.NAME'|translate}}
                    .col-sm-8
                        input#firm_name.form-control(v-model="department.firm")
                .form-group
                    label.col-sm-4.control-label(for="firm_city") {{'departments.form.CITY'|translate}}
                    .col-sm-8
                        OptiSelectCity(:city="department.firm_city" @selected="selected_firm")
                .form-group
                    label.control-label.col-sm-4(for="firm_postal") {{'departments.form.POSTAL'|translate}}
                    .col-sm-8
                        input#firm_postal.form-control(v-model="department.firm_postal")
                .form-group
                    label.col-sm-4.control-label(for="firm_street") {{'departments.form.STREET'|translate}}
                    .col-sm-8
                        input#firm_street.form-control(v-model="department.firm_street")

                .form-group(style="padding-top:30px")
                    label.col-sm-4.control-label(for="phone") {{'departments.form.PHONE'|translate}}
                    .col-sm-8
                        input#phone.form-control(v-model="department.phone")
                .form-group
                    label.col-sm-4.control-label(for="cellphone") {{'departments.form.CELLPHONE'|translate}}
                    .col-sm-8
                        input#cellphone.form-control(v-model="department.cellphone")
                .form-group
                    label.col-sm-4.control-label(for="fax") {{'departments.form.FAX'|translate}}
                    .col-sm-8
                        input#fax.form-control(v-model="department.fax")
                .form-group
                    label.col-sm-4.control-label.star-required(for="login") {{'departments.form.LOGIN'|translate}}
                    .col-sm-8
                        input#login.form-control(v-model="department.login")
                        OptiValidate(f="login" :data="$v.department.login")
                .form-group
                    label.col-sm-4.control-label(for="password") {{'departments.form.PASSWORD'|translate}}
                    .col-sm-8
                        input#password.form-control(v-model="department.password")
                        OptiValidate(f="password" :data="$v.department.password")
                .form-group
                    label.col-sm-4.control-label(for="groupId") {{'departments.form.LOGO'|translate}}
                    .col-sm-8
                        OptiSelectEnum#groupId(enum="DepartmentLogo" :value="department.groupId" @update-enum="v => department.groupId = v")
                .form-group
                    label.col-sm-4.control-label.star-required(for="priceCoefficient") {{'departments.form.PRICECOEFFICIENT'|translate}}
                    .col-sm-8
                        OptiNumber#priceCoefficient.form-control(:header="'departments.form.PRICECOEFFICIENT'|translate"
                            :value="department.priceCoefficient"
                            @confirmed="v => department.priceCoefficient = v" :float="true") {{department.priceCoefficient|number(2)}}
                        OptiValidate(f="priceCoefficient" :data="$v.department.priceCoefficient")
                .form-group
                    label.col-sm-4.control-label(for="multiplier") {{'departments.form.MULTIPLIER'|translate}}
                    .col-sm-8
                        OptiNumber#multiplier.form-control(:header="'departments.form.MULTIPLIER'|translate"
                            :value="department.multiplier"
                            @confirmed="v => department.multiplier = v" :float="true") {{department.multiplier|number(2)}}
                .form-group
                    label.col-sm-4.control-label.star-required(for="departmentGroupId") {{'departments.form.GROUP'|translate}}
                    .col-sm-8
                        select#departmentGroupId.form-control(v-model="department.departmentGroupId")
                            option(v-for="g in group" :key="g.id" :value="g.id") {{g.name}}
                        OptiValidate(f="departmentGroupId" :data="$v.department.departmentGroupId")
                .form-group
                    label.col-sm-4.control-label(for="accountNumber") {{'departments.form.ACCOUNT'|translate}}
                    .col-sm-8
                        input#accountNumber.form-control(v-model="department.accountNumber")
                .form-group
                    label.col-sm-4.control-label(for="nip") {{'departments.form.NIP'|translate}}
                    .col-sm-8
                        input#nip.form-control(v-model="department.nip")
                .form-group
                    label.col-sm-4.control-label(for="krs") KRS
                    .col-sm-8
                        input#krs.form-control(v-model="department.krs")
                .form-group
                    label.col-sm-4.control-label.star-required(for=upsCode) {{'departments.form.UPS_CODE'|translate}}
                    .col-sm-8
                        input#upsCode.form-control(v-model="department.upsCode")
                        OptiValidate(f="upsCode" :data="$v.department.upsCode")
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="department.automaticSMSMessages")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{'departments.form.SMS'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="department.canOrderLenses")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{'departments.form.SEARCHLENS'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                input(type="checkbox" v-model="department.us")
                                span.fa.fa-check(style="margin-left:0px")
                            span {{'departments.form.SC'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="bgz_contact") {{'departments.form.BGZCONTACT'|translate}}
                    .col-sm-8
                        input#bgz_contact.form-control(v-model="department.bgz_contact")
                .form-group
                    label.col-sm-4.control-label(for="takings_payments") {{'departments.form.TAKINGSPAYMENTS'|translate}}
                    .col-sm-8
                        input#takings_payments.form-control(v-model="department.takings_payments")
                .form-group
                    label.col-sm-4.control-label.star-required(for="hoya_password") {{'departments.form.HOYAPASSWORD'|translate}}
                    .col-sm-8
                        input#hoya_password.form-control(v-model="department.hoya_password")

                .form-group(style="padding-top:30px")
                    label.col-sm-4.control-label(for="visu_real_login") {{'departments.form.visureal.LOGIN'|translate}}
                    .col-sm-8
                        input#visu_real_login.form-control(v-model="department.visu_real_login")

                .form-group
                    label.col-sm-4.control-label(for="visu_real_password") {{'departments.form.visureal.PASSWORD'|translate}}
                    .col-sm-8
                        input#visu_real_password.form-control(v-model="department.visu_real_password")

                .form-group(style="padding-top:30px")
                    label.col-sm-4.control-label(for="szajna_login") {{'departments.form.szajna.LOGIN'|translate}}
                    .col-sm-8
                        input#szajna_login.form-control(v-model="department.szajna_login")

                .form-group
                    label.col-sm-4.control-label(for="szajna_password") {{'departments.form.szajna.PASSWORD'|translate}}
                    .col-sm-8
                        input#szajna_password.form-control(v-model="department.szajna_password")

                .form-group
                    .col-sm-offset-4.col-sm-8
                        OptiButtonSave(:disabled="$v.department.$invalid || $v.department.$invalid.$pending")

            .col-xs-12
                OptiDimmer(:active="loading")
</template>
<script>
    import {API} from '@/js/app/vue/api'
    import {maxLength, minLength, required, requiredIf} from 'vuelidate/lib/validators'
    import {unique, noZero} from '@/js/vue.validators'

    import OptiSelectEnum from '@/js/app/vue/components/Select/OptiSelectEnum'
    import OptiDate from '@/js/app/vue/components/OptiDate'
    import OptiSelectCity from '@/js/app/vue/components/Select/OptiSelectCity'
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
    import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiValidate from '@/js/app/vue/components/OptiValidate'

    export default {
        name: 'OptiDepartmentsListEdit',
        components: {
            OptiValidate,
            OptiDimmer,
            OptiButtonSave,
            OptiNumber,
            OptiSelectCity,
            OptiDate,
            OptiSelectEnum
        },
        data() {
            return {
                group: [],
                department: {
                    closing_date: '',
                    last_book_date: '',
                    priceCoefficient: 1
                },
                loading: true
            }
        },
        validations() {
            return {
                department: {
                    name: {
                        required,
                        unique: unique('customers', 'CUST_SHOP_NAME', this.department.id, 'CUST_CUSTOMER_ID'),
                        minLength: minLength(5),
                        maxLength: maxLength(35)
                    },
                    short: {
                        required
                    },
                    closing_date: {
                        required: requiredIf(department => department.status === 3)
                    },
                    city: {
                        required
                    },
                    postal: {
                        required
                    },
                    street: {
                        required
                    },
                    login: {
                        required,
                        unique: unique('customers', 'CUST_LOGIN', this.department.id, 'CUST_CUSTOMER_ID'),
                        minLength: minLength(5)
                    },
                    password: {
                        minLength: minLength(5)
                    },
                    priceCoefficient: {
                        noZero
                    },
                    departmentGroupId: {
                        required
                    },
                    upsCode: {
                        required
                    }
                }
            }
        },
        mounted() {
            this.getDepartmentsGroup()
            this.getDepartments()
        },
        methods: {
            editDepartment() {
                this.loading = true

                API.put('departments/', this.department).then(() => {
                    this.$notify.success('departments.notify.EDIT')

                    this.$state.go('app.departments.list')
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('departments.notify.error.EDIT')
                })
            },
            getDepartmentsGroup() {
                API.get('departments/group').then(res => {
                    this.group = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('departments.notify.error.LOADGROUP')
                })
            },
            getDepartments() {
                API.get('departments/' + this.$state.params.id).then(res => {
                    this.department = res.data

                    if(this.department.closing_date === null && this.department.last_book_date === null) {
                        this.department.closing_date = ''
                        this.department.last_book_date = ''
                    }
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('departments.notify.error.LOAD')
                })
            },
            selected(city) {
                this.department.city = city
            },
            selected_firm(city) {
                this.department.firm_city = city
            }
        }
    }
</script>